import styles from "./Header.module.css";

function Header() {
  return (
    <>
      <header className={styles.header}>
        <img src="/gmg_logo.svg" alt="GMG Logo" />
        <div>
          <h1>GRAND MONETA GEORGIA</h1>
          <p>Admin Panel</p>
        </div>
      </header>
      <hr className="pb-8" />
    </>
  );
}

export default Header;
