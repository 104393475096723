import React, { useState } from "react";
import Card from "./Card";
import styles from "./Contacts.module.css";

function Contacts({ currentBranch, updateBranch, setCurrentBranch }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedAddress, setEditedAddress] = useState(currentBranch.address);
  const [editedPhone, setEditedPhone] = useState(currentBranch.phone);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    const updatedBranch = {
      ...currentBranch,
      address: editedAddress,
      phone: editedPhone,
    };
    updateBranch(updatedBranch, (updatedData) => {
      setCurrentBranch(updatedData); // Update currentBranch with the updated data
    });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedAddress(currentBranch.address);
    setEditedPhone(currentBranch.phone);
  };

  const handleAddressChange = (event) => {
    setEditedAddress(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setEditedPhone(event.target.value);
  };

  return (
    <Card title={"Contacts"}>
      <div className={styles.contacts__container}>
        <div className={styles.contacts__container_item}>
          {isEditing ? null : <strong>Address:&nbsp;</strong>}
          {isEditing ? (
            <input
              type="text"
              value={editedAddress}
              onChange={handleAddressChange}
            />
          ) : (
            <span>{currentBranch.address}</span>
          )}
        </div>
        <div className={styles.contacts__container_item}>
          {isEditing ? null : <strong>Phone:&nbsp;</strong>}
          {isEditing ? (
            <input
              type="text"
              value={editedPhone}
              onChange={handlePhoneChange}
            />
          ) : (
            <span>{currentBranch.phone}</span>
          )}
        </div>
        {isEditing ? (
          <div className={styles.contacts__container__button_group}>
            <button
              className={styles.contacts__container__button_save}
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className={styles.contacts__container__button_cancel}
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        ) : (
          <button
            className={styles.contacts__container_button_edit}
            onClick={handleEdit}
          >
            Edit
          </button>
        )}
      </div>
    </Card>
  );
}

export default Contacts;
