import React, { useEffect } from "react";
import axios from "axios";
import Card from "./Card";
import styles from "./ConverterRates.module.css";

function ConverterRates() {
  const [ratePairs, setRatePairs] = React.useState([]);
  const [searchInput, setSearchInput] = React.useState("");
  const [editRate, setEditRate] = React.useState({
    id: "",
    rate: 0,
  });
  const server = "back.grandmonetageorgia.com";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://${server}/converter/rates`);
        setRatePairs(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (rate) => {
    setEditRate({
      id: rate._id,
      rate: rate.rate,
    });
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.patch(
        `https://${server}/converter/rate/${editRate.id}`,
        {
          rate: editRate.rate,
        }
      );

      // Handle the response, e.g., show a success message or update the data
    } catch (error) {
      console.log(error);
    }
  };

  const filteredRatePairs = ratePairs.filter((rate) =>
    rate.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <Card title={"Converter Rates"}>
      <div className={styles.converter__container}>
        <form>
          {/* <label
            htmlFor="search"
            className={`${styles.label} mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white`}
          >
            Search
          </label> */}
          <div className={`${styles.relative} relative`}>
            <div
              className={`${styles.absolute} absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none`}
            ></div>
            <input
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search Converter Rate Pairs"
              className={`${styles.input} block w-full p-4 pl-10 rounded-3xl border-2 border-gray-300 bg-white`}
              required
            />
          </div>
        </form>

        <div
          className={`${styles.grid} grid grid-cols-2 md:grid-cols-3 md:gap-6`}
        >
          {filteredRatePairs.map((rate) => (
            <div key={rate._id} className={`${styles.card} p-4`}>
              <div title={rate.description}>
                <h3 className={`${styles.heading} py-1`}>{rate.name}</h3>
                <p>
                  Rate: <strong>{rate.rate}</strong>
                  <br />
                </p>
                <button
                  className={`${styles.button} bg-ocean-blue-light block w-full text-grey py-4 rounded-3xl my-4`}
                  onClick={() => handleEdit(rate)}
                >
                  Edit Rate
                </button>
              </div>
              {editRate.id === rate._id && (
                <div>
                  <div className={`${styles.editContainer}`}>
                    <h3>Edit Rate</h3>
                    <form>
                      <input
                        type="number"
                        value={editRate.rate}
                        onChange={(e) =>
                          setEditRate({
                            ...editRate,
                            rate: e.target.value,
                          })
                        }
                        placeholder="Rate Value"
                        className={`${styles.input}`}
                      />
                      <div className={`${styles.customButtonGroup}`}>
                        <button
                          className={`${styles.button} ${styles.customSubmit}`}
                          onClick={handleUpdate}
                        >
                          Submit
                        </button>
                        <button
                          className={`${styles.button} ${styles.customCancel}`}
                          onClick={() => setEditRate({ id: "", rate: 0 })}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}

export default ConverterRates;
