import "./App.css";
import React, { useState, useEffect } from "react";
import Header from "./Components/Header";
import Auth from "./Components/Auth";
import Main from "./Components/Main";
import Footer from "./Components/Footer";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    typeof localStorage !== "undefined" &&
      localStorage.getItem("isLoggedIn") === "true"
  );
  return (
    <>
      <Header />
      {console.log(isLoggedIn)}
      {isLoggedIn ? <Main /> : <Auth setIsLoggedIn={setIsLoggedIn} />}
      <Footer />
    </>
  );
}

export default App;
