import Card from "./Card";
import { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Users.module.css";

function Users({ currentBranch }) {
  const [userInfo, setUserInfo] = useState({});
  const server = "back.grandmonetageorgia.com";

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(
          `https://${server}/user/${currentBranch.manager}`
        );
        const { fullName, email } = response.data;
        setUserInfo({ fullName, email });
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserInfo();
  }, [currentBranch.manager]);

  return (
    <Card title={"Users"}>
      <div className={styles.users__container}>
        <p>{userInfo.fullName}</p>
        <i>{userInfo.email}</i>
        <button disabled>Edit</button>
      </div>
    </Card>
  );
}

export default Users;
