import Location from "./Location";
import Contacts from "./Contacts";
import Users from "./Users";
import Rates from "./Rates";
import ConverterRates from "./ConverterRates";
import axios from "axios";
import { useEffect, useState } from "react";
import styles from "./Main.module.css";

function Main() {
  const [branches, setBranches] = useState([]);
  const [currentBranch, setCurrentBranch] = useState({});
  const server = "back.grandmonetageorgia.com";

  useEffect(() => {
    fetch(`https://${server}/branches/`)
      .then((response) => response.json())
      .then((data) => {
        setBranches(data);
        setCurrentBranch(data[0]); // Set currentBranch to the first branch
      });
  }, []);

  const updateBranch = async (updatedBranch) => {
    try {
      const response = await axios.patch(
        `https://${server}/branch/${updatedBranch._id}`,
        {
          address: updatedBranch.address,
          phone: updatedBranch.phone,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.main__container}>
      <div className={styles.main__container_top}>
        {/* <Location branches={branches} setCurrentBranch={setCurrentBranch} /> */}
        <Contacts currentBranch={currentBranch} updateBranch={updateBranch} />
        {/* <Users currentBranch={currentBranch} /> */}
      </div>
      <hr className="my-12" />
      <Rates />
      <ConverterRates />
    </div>
  );
}

export default Main;
