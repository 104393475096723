import React, { useEffect } from "react";
import axios from "axios";
import Card from "./Card";
import styles from "./Rates.module.css";

function Rates() {
  const [ratePairs, setRatePairs] = React.useState([]);
  const [searchInput, setSearchInput] = React.useState(""); // State for the search input
  const [editRate, setEditRate] = React.useState({
    id: "",
    rate: { buy: 0, sell: 0 },
  });
  const [isEditing, setIsEditing] = React.useState(false);
  const server = "back.grandmonetageorgia.com";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://${server}/rates`);
        setRatePairs(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // Function to handle rate editing
  const handleEdit = (rate) => {
    setEditRate({
      id: rate._id,
      rate: { buy: rate.rate.buy, sell: rate.rate.sell },
    });
  };

  // Function to handle rate update submission
  const handleUpdate = async (e) => {
    // e.preventDefault(); // Prevent the default form submission behavior

    try {
      const response = await axios.patch(
        `https://${server}/rate/${editRate.id}`,
        {
          rate: editRate.rate,
        }
      );

      // Handle the response, e.g., show a success message or update the data
      // You may want to update the filteredRatePairs with the updated data
    } catch (error) {
      console.log(error);
    }
  };

  // Function to filter the ratePairs based on the search input
  const filteredRatePairs = ratePairs
    .filter((rate) =>
      rate.name.toLowerCase().includes(searchInput.toLowerCase())
    )
    .sort((a, b) => a.position - b.position);

  return (
    <Card title={"Rate Pairs"}>
      <div className={styles.rates__container}>
        <form>
          {/* <label
            htmlFor="search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label> */}
          <div className={styles.searchbar_container}>
            <div className={styles.searchbar_item}></div>
            <input
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)} // Update the search input state
              placeholder="Search Rate Pairs"
              className={styles.searchbar_input}
              required
            />
          </div>
        </form>

        <div className={styles.customGrid}>
          {filteredRatePairs.map((rate) => (
            <div key={rate._id} className={styles.customCard}>
              <div title={rate.description}>
                <h3 className={styles.customHeading}>{rate.name}</h3>
                <p>
                  BUY: <strong>{rate.rate.buy}</strong>{" "}
                  <span className={styles.customDot}>&#8226;</span>{" "}
                  <br className={styles.customBreak} /> SELL:{" "}
                  <strong>{rate.rate.sell}</strong>
                  <br />
                </p>
                <button
                  className={styles.customButton}
                  onClick={() => {
                    handleEdit(rate);
                    setIsEditing(true);
                  }}
                >
                  Edit Rate
                </button>
              </div>
              {isEditing && editRate.id === rate._id && (
                <div>
                  <div className={styles.editContainer}>
                    <h3>Edit Rate</h3>
                    <form onSubmit={(e) => handleUpdate(e)}>
                      <input
                        type="number"
                        value={editRate.rate.buy}
                        onChange={(e) =>
                          setEditRate({
                            ...editRate,
                            rate: { ...editRate.rate, buy: e.target.value },
                          })
                        }
                        placeholder="Buy Rate Value"
                        className={styles.customInput}
                      />
                      <input
                        type="number"
                        value={editRate.rate.sell}
                        onChange={(e) =>
                          setEditRate({
                            ...editRate,
                            rate: { ...editRate.rate, sell: e.target.value },
                          })
                        }
                        placeholder="Sell Rate Value"
                        className={styles.customInput}
                      />
                      <div className={styles.customButtonGroup}>
                        <button
                          className={styles.customSubmit}
                          onClick={() => {
                            handleUpdate();
                            setIsEditing(false);
                          }}
                        >
                          Submit
                        </button>
                        <button
                          className={styles.customCancel}
                          onClick={() => {
                            setEditRate({ id: "", rate: { buy: 0, sell: 0 } });
                            setIsEditing(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}

export default Rates;
