import styles from "./Footer.module.css";

function Footer() {
  return (
    <>
      <hr />
      <footer className={styles.footer}>
        <p className="text-gray">
          Contact for support: <a href="https://t.me/kawaiier">@kawaiier</a>
        </p>
      </footer>
    </>
  );
}

export default Footer;
