import React, { useState, useEffect } from "react";
import Card from "./Card";
import styles from "./Location.module.css";

function Location({ branches, setCurrentBranch }) {
  const [selectedLocation, setSelectedLocation] = useState("");

  useEffect(() => {
    if (branches.length > 0) {
      setSelectedLocation(branches[0].name);
      setCurrentBranch(branches[0]);
    }
  }, [branches, setCurrentBranch]);

  const handleLocationChange = (event) => {
    const selectedName = event.target.value;
    const selectedBranch = branches.find(
      (branch) => branch.name === selectedName
    );
    setSelectedLocation(selectedName);
    setCurrentBranch(selectedBranch);
    console.log("selected", selectedBranch);
  };

  return (
    <Card title={"Location"}>
      <p>
        Choose branch <br /> you want to edit
      </p>
      <select
        className={styles.location__select}
        value={selectedLocation}
        onChange={handleLocationChange}
      >
        {branches.map((branch) => (
          <option key={branch._id} value={branch.name}>
            {branch.name}
          </option>
        ))}
      </select>
    </Card>
  );
}

export default Location;
