import React, { useState } from "react";
import axios from "axios";
import styles from "./Auth.module.css";

function Auth({ setIsLoggedIn }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const server = "back.grandmonetageorgia.com";

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the form from submitting by default

    try {
      // Make a POST request to your login endpoint with the email and password
      const response = await axios.post(`https://${server}/auth/login`, {
        email,
        password,
      });

      // Assuming your backend returns some kind of authentication token upon successful login
      // You can save the token in local storage or a state variable to indicate that the user is logged in
      const authToken = response.data.token;
      // You might also want to save other user information as needed

      // Set the "isLoggedIn" state to true, indicating that the user is logged in
      setIsLoggedIn(true);
      localStorage.setItem("isLoggedIn", true);
    } catch (error) {
      // Handle login error, e.g., show an error message to the user
      console.error("Login failed:", error);
      setErrorMessage("Email or password is incorrect."); // Set the error message
    }
  };

  return (
    <div className={styles.auth__container}>
      <h1 className="text-3xl">Authorization</h1>
      <form onSubmit={handleLogin} className={styles.auth__container_form}>
        <input
          type="email"
          placeholder="Email"
          className="text-sm block w-full p-2.5 my-4 rounded-3xl border-2	 border-gray-300 bg-white"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          className="text-sm block w-full p-2.5 my-4 rounded-3xl border-2	 border-gray-300 bg-white"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          type="submit"
          className="bg-ocean-blue-dark block w-full text-white py-4 rounded-3xl my-4"
        >
          Log In
        </button>
      </form>
      {errorMessage && <div className="text-red-600">{errorMessage}</div>}
    </div>
  );
}

export default Auth;
